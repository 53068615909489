import React, { useContext, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useValidPassword, useValidatedPassword } from 'src/hooks/useAuthHooks'
import { Password, PasswordWithValidation } from 'src/components/auth/authComponents'
import { AuthContext } from 'src/contexts/authContext'
import {
  CButton,
  CCard,
  CCardBody,
  CCol,
  CForm,
  CInputGroup,
  CRow,
  CModal,
  CSpinner,
} from '@coreui/react-pro'
import CIcon from '@coreui/icons-react'
import { cilXCircle } from '@coreui/icons'
import Notifications from 'src/components/shared/notifications/NotificationsToaster'
import signupBackgroundImage from 'src/assets/backgrounds/modals/signup/bg-signup.png'
import { GlobalVariablesContext } from 'src/contexts/globalVariablesContext'

const ChangePassword = ({ modalVisibility }) => {
  const [error, setError] = useState('')
  const { setChangePasswordModalVisible } = useContext(GlobalVariablesContext)
  const [isSpinnerVisible, setIsSpinnerVisible] = useState(false)
  const { password, setPassword, passwordIsValid } = useValidatedPassword('')
  const {
    password: passwordAgain,
    setPassword: setPasswordAgain,
    passwordIsValid: passwordAgainIsValid,
  } = useValidatedPassword('')
  const {
    password: oldPassword,
    setPassword: setOldPassword,
    passwordIsValid: oldPasswordIsValid,
  } = useValidPassword('')

  const isValid =
    !oldPasswordIsValid ||
    oldPassword.length === 0 ||
    password.length === 0 ||
    !passwordAgainIsValid ||
    !passwordIsValid ||
    password !== passwordAgain

  const { t } = useTranslation()
  const authContext = useContext(AuthContext)
  const emailAttr = authContext.attrInfo.find((attr) => attr.Name === 'email')
  const email = emailAttr && emailAttr.Value

  const changePassword = async () => {
    try {
      await authContext.changePassword(oldPassword, password)
      await authContext.signInWithEmail(email, password)
      setChangePasswordModalVisible(false)
    } catch (err) {
      setIsSpinnerVisible(false)
      setError(err.message)
    }
  }

  const closeModal = () => {
    setChangePasswordModalVisible(false)
  }

  //required to clear error message from state to avoid rerendering
  useEffect(() => {
    setTimeout(() => {
      setError('')
    }, 3000)
  }, [error])

  return (
    <div className={`flex-row ${modalVisibility ? 'd-flex' : 'd-none'}`}>
      {error ? <Notifications type="error" message={error} /> : null}
      {/* Honey */}
      {/* Honey */}
      <CModal
        className="show d-block spartan-font"
        backdrop="static"
        keyboard={false}
        portal={false}
        size="xl"
        visible={modalVisibility}
        alignment="center"
        onClose={() => closeModal()}
      >
        <CRow className="justify-content-center">
          <CCol
            style={{
              backgroundImage: `url(${signupBackgroundImage})`,
              backgroundSize: `cover`,
              borderRadius: `0px`,
            }}
          ></CCol>
          <CCol className="col-lg-7 ps-0">
            <CCard style={{ borderRadius: `0px` }}>
              <div className=" d-flex flex-row justify-content-end align-items-end m-3">
                <CIcon
                  role="button"
                  icon={cilXCircle}
                  className="icon-xl"
                  onClick={() => closeModal()}
                />
              </div>
              <CCardBody className="m-5 p-xl-5">
                <div className="d-flex flex-column mb-4">
                  <h2>{t('Authentication.SetUpPassword.Header.SetUpPassword')}</h2>
                </div>
                <CForm>
                  <CInputGroup className="mb-4">
                    <Password
                      label={t('Authentication.SetUpPassword.Label.OldPassword')}
                      isValid={oldPasswordIsValid}
                      setPassword={setOldPassword}
                    />
                  </CInputGroup>
                  <CInputGroup className="mb-4 flex-column">
                    <PasswordWithValidation
                      password={password}
                      setPassword={setPassword}
                      passwordAgain={passwordAgain}
                      setPasswordAgain={setPasswordAgain}
                    />
                  </CInputGroup>
                  <CRow>
                    <CCol>
                      <div className="d-flex justify-content-center align-items-end flex-column ">
                        <CButton
                          disabled={isValid}
                          onClick={changePassword}
                          className="px-4 mb-4 text-white bg-black"
                        >
                          {isSpinnerVisible ? (
                            <CSpinner color="warning" variant="grow" size="sm" />
                          ) : (
                            t('Authentication.SetUpPassword.Button.SAVE')
                          )}
                        </CButton>
                      </div>
                    </CCol>
                  </CRow>
                </CForm>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
      </CModal>
    </div>
  )
}

export default ChangePassword
